import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';

import { PointReward } from 'minga/domain/points';

import { LayoutService } from '@modules/layout/services';

import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { MediaService } from '@shared/services/media';

import { PointsManagerMessages } from '../../constants';
import { PmRewardsEditComponent } from './components/pm-rewards-edit/pm-rewards-edit.component';
import { PM_REWARDS_TABLE_COLUMNS, PmRewardsMessages } from './constants';
import { PmRewardsService } from './services';
import { PmRewardsEditModalData, PmRewardsEditModalResponse } from './types';

@Component({
  selector: 'mg-pm-rewards',
  templateUrl: './pm-rewards.component.html',
  styleUrls: ['./pm-rewards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PmRewardsComponent implements OnInit, OnDestroy {
  @ViewChild(MatTable) matTable: MatTable<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  /** Constants */
  public readonly PM_MESSAGES = PointsManagerMessages;
  public readonly MESSAGES = PmRewardsMessages;

  /** General Observables */
  private readonly _destroyed = new ReplaySubject<void>(1);
  private readonly _isLoading = new BehaviorSubject(true);
  public readonly isLoading$ = this._isLoading
    .asObservable()
    .pipe(shareReplay());

  /** Table Data */
  public readonly newCreatedRewardId$: Observable<number>;
  public readonly dataSource = new MatTableDataSource<PointReward>([]);
  public readonly tableData$ = this.rewardsService.rewardTypes$
    .pipe(takeUntil(this._destroyed))
    .subscribe(data => {
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
      this._cdr.markForCheck();
    });

  public readonly displayedColumns$ = this._media.breakpoint$.pipe(
    map(bp =>
      ['xsmall', 'small'].includes(bp) ? ['mobile'] : PM_REWARDS_TABLE_COLUMNS,
    ),
  );

  /** Component Constructor */
  constructor(
    public layout: LayoutService,
    public rewardsService: PmRewardsService,
    private _cdr: ChangeDetectorRef,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _modalOverlay: ModalOverlayService<
      PmRewardsEditModalResponse,
      PmRewardsEditModalData
    >,
    private _media: MediaService,
  ) {}

  ngOnInit(): void {
    this._initialLoad();
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
    this._isLoading.complete();
    this.tableData$.unsubscribe();
  }

  public async openEditModal(id?: number): Promise<void> {
    const editModal = this._modalOverlay.open(PmRewardsEditComponent, {
      data: { id },
      disposeOnNavigation: false,
    });
    editModal.afterClosed.subscribe(async response => {
      if (!response) return;
      const { type } = response;
      switch (type) {
        case ModalOverlayServiceCloseEventType.CREATE: {
          this._systemAlertSnackBar.success(
            PmRewardsMessages.SNACKBAR_SUCCESS_CREATE,
          );
          return;
        }
        case ModalOverlayServiceCloseEventType.SUBMIT: {
          await this.rewardsService.fetchRewardTypes();
          this._systemAlertSnackBar.success(
            PmRewardsMessages.SNACKBAR_SUCCESS_SUBMIT,
          );
          return;
        }
        case ModalOverlayServiceCloseEventType.DELETE: {
          this._systemAlertSnackBar.success(
            PmRewardsMessages.SNACKBAR_SUCCESS_DELETE,
          );
          return;
        }
        default: {
          return;
        }
      }
    });
  }

  public trackByFn(index, rewardType: PointReward): TrackByFunction<number> {
    return rewardType.id || index;
  }

  private async _initialLoad(): Promise<void> {
    this._isLoading.next(true);
    await this.rewardsService.fetchRewardTypes();
    this._isLoading.next(false);
  }
}
