import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { EventsFacadeService } from 'minga/app/src/app/events/services';
import { GroupsFacadeService } from 'minga/app/src/app/groups/services';
import {
  ContentEvents,
  IContentEventGoingErrorEvent,
  IContentEventGoingSuccessEvent,
} from 'minga/app/src/app/minimal/services/ContentEvents';
import {
  EventContentService,
  IEventManagementCounts,
} from 'minga/app/src/app/minimal/services/EventContent';
import {
  IOverlayConfig,
  IOverlayConfigurable,
} from 'minga/app/src/app/misc/overlay';
import { EventGalleryService } from 'minga/app/src/app/routes/gallery/services/EventGallery';
import { ContentState } from 'minga/app/src/app/services/ContentState';
import { ICalExportService } from 'minga/app/src/app/services/ICalExport';
import { LinkOpenerService } from 'minga/app/src/app/services/LinkOpener';
import {
  adjustAllDayEventForDifferentTimezone,
  checkIfAllDayEventIsFromAnotherTimezone,
} from 'minga/app/src/app/util/event';
import { displayTime, isEventOver } from 'minga/app/src/app/util/eventDates';
import { IContentEventMinimal } from 'minga/domain/content';
import { EventStatus } from 'minga/domain/event';
import { MembershipListType } from 'minga/domain/membershipList';
import { LongEventCardView } from 'minga/proto/gateway/content_views_pb';
import { isMultiDay, MingaPermission } from 'minga/util';
import { EventPeopleComponent } from 'src/app/components/Overlay/EventPeople/EventPeople.component';
import { EventMessages } from 'src/app/events';
import { PermissionsService } from 'src/app/permissions';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { PeopleSelectorService } from '@modules/people-selector';

import { MembershipListTableLists } from '@shared/components/membership-list-table';
import { ModalOverlayService } from '@shared/components/modal-overlay';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';

import { LongCardBaseClass } from '../LongCardBase/LongCardBaseClass';

@Component({
  selector: 'mg-event-long-card',
  templateUrl: './EventLongCard.component.html',
  styleUrls: ['./EventLongCard.component.scss'],
  providers: [EventGalleryService],
})
export class EventLongCardComponent
  extends LongCardBaseClass
  implements OnDestroy, OnInit, OnChanges, IOverlayConfigurable, AfterViewInit
{
  eventReasonId = 0;
  content?: LongEventCardView.AsObject;
  EventStatus = EventStatus;
  MESSAGES = EventMessages;
  windowWidth = 400;
  longtime = '';
  time = '';
  contentMinimal: IContentEventMinimal | undefined = undefined;
  // disable gallery outside of the minga gallery feature setting
  disableEventPhotoGallery = false;
  // minga gallery feature setting, true if enabled for the minga
  readonly enableGalleryFeatureSetting$: Observable<boolean>;
  eventStatus$?: Observable<EventStatus>;

  eventManagementCounts$: Observable<IEventManagementCounts>;
  isPhotoGalleryEnabled$: Observable<boolean>;
  isCheckinEnabled$: Observable<boolean>;

  canManageEvent = false;
  addingEventToCalendaar = false;
  canAccessManagerList = false;
  readonly isMobileDevice: boolean =
    window.MINGA_DEVICE_ANDROID || window.MINGA_DEVICE_IOS;

  public lists: MembershipListTableLists[] = [];
  @Input() showTopNav = true;

  @ViewChild('eventImageWrap', { static: false })
  eventImageWrap: ElementRef;

  readonly bannerImageSizes: ReadonlyArray<string | string[]> = [
    'blurloading1',
    ['longcardbanner', 'cardbanner', 'banner'],
  ];

  private _contentEventGoingSub?: Subscription;
  private _contentEventGoingSuccessSub?: Subscription;
  private _contentEventGoingErrorSub?: Subscription;

  @HostListener('window:resize', ['$event'])
  onResize(e) {
    this.updateScreenSizing();
  }

  constructor(
    contentEvents: ContentEvents,
    private eventContentService: EventContentService,
    public media: MediaObserver,
    router: Router,
    private eventsFacade: EventsFacadeService,
    public galleryService: EventGalleryService,
    groupFacade: GroupsFacadeService,
    contentState: ContentState,
    private linkOpener: LinkOpenerService,
    private cdr: ChangeDetectorRef,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private calService: ICalExportService,
    private _settingService: MingaSettingsService,
    private _peopleSelector: PeopleSelectorService,
    private _modalOverlayService: ModalOverlayService,
    private _permissionService: PermissionsService,
  ) {
    super(contentEvents, groupFacade, contentState, router);

    this.isCheckinEnabled$ = this._settingService.isCheckinModuleEnabled();
  }

  registerOnOverlayConfig(fn: (config: IOverlayConfig) => void) {
    fn({
      contentBackground: '#ffffff',
      fullContentBackground: '#ffffff',
    });
  }

  gotoEventGallery(galleryPhotoHash: string = '') {
    this.router.navigate(['', { outlets: { o: null } }]).then(() => {
      const route = ['gallery', 'event', this.contextHash];
      if (galleryPhotoHash) {
        route.push(galleryPhotoHash);
      }
      this.router.navigate(route);
    });
  }

  getEventImageUrl() {
    return this.getImageUrl(this.content.image, [
      'longcardbanner',
      'cardbanner',
      'banner',
    ]);
  }

  updateScreenSizing() {
    this.windowWidth = window.innerWidth;
  }

  isEventOver() {
    return isEventOver(
      this.content.startTimestamp,
      this.content.endTimestamp,
      this.content?.allDay,
    );
  }

  getStartDateComponent(options: any): string {
    let time = '';
    let timestamp = this.content.startTimestamp;
    let moredays = true;
    const multiDay = this.checkMultiDay();
    while (this.content && moredays) {
      const date = new Date(timestamp);

      if (
        this.content.allDay &&
        checkIfAllDayEventIsFromAnotherTimezone(date)
      ) {
        adjustAllDayEventForDifferentTimezone(date);
      }

      time += date.toLocaleString('en-us', options).toUpperCase();
      if (timestamp === this.content.endTimestamp) {
        if (time.length > 4) {
          this.longtime = 'long-day-duration';
        }
        moredays = false;
      } else if (options.day === 'numeric' && multiDay) {
        timestamp = this.content.endTimestamp;
        time += '-';
      } else {
        moredays = false;
      }
    }

    this.cdr.markForCheck();
    return time;
  }

  checkMultiDay() {
    if (this.content) {
      const start = new Date(this.content.startTimestamp);
      const end = new Date(this.content.endTimestamp);
      return isMultiDay(start, end, this.content.allDay);
    }
    return false;
  }

  getTime(timeStamp: number): string {
    if (this.content) {
      return displayTime(timeStamp);
    }
    return '';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.content) {
      this._handleCanManageEvent();
    }
  }

  ngOnInit() {
    if (this.content) {
      this.eventReasonId = this.content.eventReasonId;
      this._handleCanManageEvent();

      if (this.content.ownerGroupHash) {
        this.ownerGroupHash.next(this.content.ownerGroupHash);
      }
    }

    if (this.contextHash) {
      this._contentEventGoingSub = this.eventContentService
        .observeGoingCountChange(this.contextHash)
        .subscribe(ev => this._onContentEventGoing(ev));
      this.eventManagementCounts$ =
        this.eventContentService.getEventManagementInfo(this.contextHash);
      this.eventContentService.triggerEventManagementUpdate(this.contextHash);
    }

    this._contentEventGoingSuccessSub =
      this.contentEvents.onContentEventGoingSuccess
        .pipe(filter(ev => ev.contentContextHash === this.contextHash))
        .subscribe(ev => this._onContentEventGoingSuccess(ev));

    this._contentEventGoingErrorSub =
      this.contentEvents.onContentEventGoingError
        .pipe(filter(ev => ev.contentContextHash === this.contextHash))
        .subscribe(ev => this._onContentEventGoingError(ev));

    this.updateScreenSizing();

    this.initGalleryData();
    this.initEventManagerList();

    this.time = this.getStartDateComponent({ day: 'numeric' });
  }

  ngAfterViewInit() {
    if (this.contextHash) {
      this.eventStatus$ = this.eventContentService.observeCheckIn(
        this.contextHash,
      );
    }
  }

  initEventManagerList() {
    if (!this.contextHash) return;

    this.lists = [
      {
        contextHash: this.contextHash,
        type: MembershipListType.CONTENT_MANAGER,
      },
    ];

    this.cdr.detectChanges();
  }

  async initGalleryData() {
    if (this.contextHash) {
      this.contentMinimal = await this.eventsFacade.fetchEventByContextHash(
        this.contextHash,
      );
      this.galleryService.setEventContent(this.contentMinimal);
    }

    this.isPhotoGalleryEnabled$ =
      this._settingService.isPhotoGalleryModuleEnabled();
  }

  reloadLists() {
    this.initEventManagerList();
  }

  private _onContentEventGoing(increment: number) {
    if (this.content) {
      this.content.goingCount = Math.max(
        0,
        this.content.goingCount + increment,
      );
    }

    this.cdr.markForCheck();
  }

  private _onContentEventGoingSuccess(ev: IContentEventGoingSuccessEvent) {
    // This isn't necessary
  }

  private _onContentEventGoingError(ev: IContentEventGoingErrorEvent) {
    this._systemAlertSnackBar.error(
      'Oops! There was an error trying to do that! Please try again later',
    );
  }

  ngOnDestroy() {
    if (this._contentEventGoingErrorSub) {
      this._contentEventGoingErrorSub.unsubscribe();
    }
    if (this._contentEventGoingSub) {
      this._contentEventGoingSub.unsubscribe();
    }
    if (this._contentEventGoingSuccessSub) {
      this._contentEventGoingSuccessSub.unsubscribe();
    }
  }

  goingCountClick(e) {
    this.eventContentService.emitSeeEventPeopleGoing({
      contextHash: this.contextHash,
      content: this.content,
    });
  }

  analyticsIconClicked(status: EventStatus) {
    this._modalOverlayService.open(EventPeopleComponent, {
      data: {
        eventTitle: this.content.title,
        contextHash: this.contextHash,
        hasTickets: this.content.hasTickets,
        status,
      },
    });
  }

  async addEventToCalendar() {
    if (this.addingEventToCalendaar) return;
    if (this.content) {
      this.addingEventToCalendaar = true;
      await this.calService.addLongEventContentToCalendar(
        this.content,
        this.contentHash || '',
      );
      // hold off on allowing clicks for a couple seconds
      setTimeout(() => {
        this.addingEventToCalendaar = false;
      }, 2000);
    }
  }

  async launchCheckInSelector() {
    if (!this.contextHash) {
      return;
    }
    // let the people selector know this event needs tickets.
    this.eventContentService.setTicketsRequired(
      this.contextHash,
      this.content?.hasTickets || false,
    );

    this._peopleSelector.open('Event', 'checkin', {
      title: 'Event Check In',
      data: {
        contextHash: this.contextHash,
        eventReasonId: this.eventReasonId,
      },
    });
  }

  launchCheckOutSelector() {
    if (!this.contextHash) {
      return;
    }
    // let the people selector know this event needs tickets.
    this.eventContentService.setTicketsRequired(
      this.contextHash,
      this.content?.hasTickets || false,
    );

    this._peopleSelector.open('Event', 'checkout', {
      title: 'Event Check Out',
      data: {
        contextHash: this.contextHash,
        eventReasonId: this.eventReasonId,
      },
    });
  }

  launchAddEventManagerSelector() {
    this.router.navigate(
      [
        '',
        {
          outlets: {
            o: ['view', 'event~people', 'add-event-manager', this.contextHash],
          },
        },
      ],
      {},
    );
  }

  launchEventManagerList() {
    this.router.navigate(
      [
        '',
        {
          outlets: {
            o: ['view', 'event~people', 'event-managers', this.contextHash],
          },
        },
      ],
      {},
    );
  }

  async launchInvite() {
    if (!this.contextHash) return;
    // let the invite list know this event needs tickets.
    this.eventContentService.setTicketsRequired(
      this.contextHash,
      this.content?.hasTickets || false,
    );
    await this._peopleSelector.open('Event', 'invite', {
      title: 'Invite to Event',
      data: {
        contextHash: this.contextHash,
        eventReasonId: this.eventReasonId,
      },
    });
  }

  launchEventTicketsList() {
    this.router.navigate(
      [
        '',
        {
          outlets: {
            o: ['view', 'event~people', 'event-tickets', this.contextHash],
          },
        },
      ],
      {},
    );
  }

  openBuyTicketUrl() {
    if (this.content?.ticketUrl) {
      this.linkOpener.open(this.content?.ticketUrl);
    }
  }

  private _handleCanManageEvent() {
    this.eventContentService.checkIfCanManageEvent(this.content).then(value => {
      this.canManageEvent = value;
      if (this.canManageEvent) {
        this.canAccessManagerList = this._permissionService.hasPermission(
          MingaPermission.CONTENT_EVENT_CREATE,
        );
      } else {
        this.canAccessManagerList = false;
      }
      this.cdr.markForCheck();
    });
  }
}
